<template>
  <q-dialog persistent>
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-section>
        <div class="text-h6 text-left q-ml-md q-pb-xs text-weight-bold">Política de privacidad</div>
      </q-card-section>
      <q-card-section>
          <p class="text-left q-ma-md">
      En virtud de lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento
      Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de
      las personas físicas en lo que respecta al tratamiento de datos personales
      y a la libre circulación de estos datos, se le informa de lo siguiente:
      <br /><br />
      Los datos que nos comunique a través de esta página web serán objeto de
      tratamiento.
      <br /><br />
      El responsable del tratamiento de sus datos es SOMOS SINAPSIS, S.L., con
      CIF B66099763, y domicilio en C/Tamarit 99, local 1 – 08015 Barcelona. Su
      dirección de correo electrónico para cualquier tema relativo al
      tratamiento de datos de carácter personal es legal@somosinapsis.com.
      <br /><br />
      Es posible ponerse en contacto con el Delegado de Protección de
      Datos del responsable del tratamiento a través de la siguiente dirección
      de correo electrónico legal@somosinapsis.com
      <br /><br />
      La finalidad del tratamiento es la prestación de los servicios o la
      entrega de los productos solicitados por el cliente. Dichos datos podrán
      ser comunicados a las empresas de mensajería o logística encargadas del
      transporte y entrega de los productos. Igualmente los datos podrán ser
      comunicados, en su caso, a la entidad de crédito prestadora de los
      servicios del pago dentro del marco de la gestión del pago. El tratamiento
      incluye la conservación de los datos, la comunicación a los departamentos
      apropiados de cada empresa e incluso la comunicación de los datos entre
      las distintas empresas del grupo.
      <br /><br />
      Los datos del interesado también podrán ser comunicados a empresas
      colaboradoras del responsable, con la finalidad de prestarle servicios
      similares a los solicitados por el interesado al responsable.
      <br /><br />
      Las bases jurídicas del tratamiento son: 
      <ul class="text-left q-ma-md">
        <li>La ejecución de una relación
      contractual (artículo 6.1.b) RGPD). Esta base jurídica se usará para todos
      los tratamientos que tengan que ver con la prestación de servicios o
      entrega de productos solicitados por el interesado.</li>
      <li>El consentimiento prestado por el interesado (artículo 6.1.a) RGPD). Esta base jurídica se usará para los tratamientos no relacionados de forma directa con dicha prestación de servicios pero recogidos de forma expresa en otros medios que haya aceptado expresamente el interesado.</li>
      <li>El cumplimiento de obligaciones legales (artículo 6.1.c) RGPD). Esta base jurídica se usará para aquellos tratamientos que esté obligado a hacer el responsable con los datos del interesado. Por ejemplo: tratamientos relativos a la Administración Tributaria o a la legislación laboral.</li>
      <li>La satisfacción de intereses legítimos del responsable (artículo 6.1.f) RGPD). Esta base jurídica se usará para aquellos tratamientos en los que no prevalezca un derecho o libertad del interesado pero que puedan favorecer el ejercicio de los citados intereses legítimos. Por ejemplo: prevención del fraude, garantizar la seguridad de la red o el tratamiento de datos con fines de mercadotecnia directa.</li>
      </ul>
    </p>
    <p class="text-left q-ma-md">
      Es necesario que el interesado proporcione los datos para poder prestar los servicios encomendados o entregar los bienes solicitados. En caso de no facilitarse dichos datos, el responsable del tratamiento no podrá llevar a cabo dichos servicios o entrega de bienes.
      <br /><br />
      Sus datos personales serán eliminados transcurrido el plazo legalmente establecido para el cumplimiento de nuestras obligaciones legales, más un periodo adicional de cuatro años, siempre y cuando el interesado no haya autorizado expresamente la continuación de su uso.
      <br /><br />
      Los datos personales serán conservados durante el plazo de conservación legalmente obligatorio y un periodo adicional de cuatro años.
      <br /><br />
      El interesado tiene la posibilidad de solicitar al responsable el acceso a sus datos personales, su rectificación, su supresión, la limitación de su tratamiento, oponerse a dicho tratamiento o ejercer la portabilidad de los datos que sean tratados de forma automatizada.
      <br /><br />
      En caso de conflicto, el interesado puede acudir a la Agencia Española de Protección de Datos o a cualquier otra autoridad de control competente para entender de sus reclamaciones.
      <br /><br />
      El interesado puede retirar su consentimiento en cualquier momento, sin que eso afecte a la licitud de los tratamientos basados en el consentimiento previo a su retirada.
      <br /><br />
      Los datos del interesado no serán usados para decisiones individuales automatizadas.
          </p>
    
      </q-card-section>
      <q-card-actions align="center">
          <q-btn :style="{ backgroundColor: settings.color_primary}" label="Acepto las políticas de privacidad" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.q-btn {
    border-radius: 25px 25px 25px 0px;
    margin-bottom: 10px;
    color: white;
    text-transform: capitalize;
    height: 48px;
    padding-left: 5%;
    padding-right: 5%;

}
</style>
